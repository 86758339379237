
import { VIDEO_HOST } from "@/api/api";
import router from "@/router";
import { useStore } from "@/store";
import { defineComponent, onMounted, reactive, ref } from "vue";

export default defineComponent({
  name: "Online Care Video",
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();

    const userName = ref(store.getters.getAccountInfo?.UserName);

    const roomId = ref<string>("");

    roomId.value = props.id.toString();

    const style = reactive({
      width: "100%",
    });
    const iframe = reactive({
      src: "",
      style,
      wrapperStyle: null,
    });

    onMounted(() => {
      window.addEventListener("message", (e: any) => {
        if (e.data.toString() === "exit") router.go(-1);
      });
      iframe.src = `${VIDEO_HOST}/room/CASE_${props.id}?displayName=${userName.value}`;
      state.isLoading = false;
    });
    const state = reactive({ iframe, isLoading: true });

    return { state, roomId, userName };
  },
});
